<template>
  <div class="mt-1">
    <!-- Table Container Card -->

    <b-card>
      <b-overlay :show="loading" rounded="sm" no-fade>
        <b-row class="mb-1">
          <!-- Per Page -->
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries </label>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-input v-model="searchQuery" class="d-inline-block" cle placeholder="Search..." />
          </b-col>

          <b-col cols="12" md="3">
            <treeselect v-model="selectedDepartment" :multiple="true" :options="departments" :limit="1" placeholder="All Departments" />
          </b-col>

          <b-col cols="12" md="3">
            <treeselect v-model="selectedLab" :multiple="true" :options="labs" :limit="1" placeholder="All Labs" />
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col cols="12" md="12" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
                      <b-button variant="primary" @click="exportExcel" class="mr-1">
                      Export
                      </b-button>
                     
                    </b-col>



        </b-row>

        <b-table hover ref="refCustomerListTable" class="position-relative" :items="fetchCustomers" :outlined="true" selectable select-mode="single" responsive :small="true" :fields="tableColumns" show-empty empty-text="No matching records found" @row-selected="onRowSelected" :per-page="perPage">
          <!-- Column: Id -->
          <template #cell(id)="data">
            <!--   <b-link :to="{ name: 'customer-edit', params: { id: data.item.id } }" class="font-weight-bold"> #{{ data.value }} </b-link>-->
            <strong class="text-primary">#{{ data.value }}</strong>
          </template>

          <!-- Column: Name -->
          <template #cell(name)="data">
            {{ data.value }}
          </template>

          <!-- Column: Name -->
          <template #cell(surname)="data">
            {{ data.value }}
          </template>

          <!-- Column: Role -->
          <template #cell(role)="data">
            <b-badge pill :variant="`light-primary`" class="text-capitalize">
              {{ data.value }}
            </b-badge>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge v-if="data.value == 'active'" pill :variant="`light-success`" class="text-capitalize">
              Active
            </b-badge>

            <b-badge v-if="data.value == 'passive'" pill :variant="`light-danger`" class="text-capitalize">
              Passive
            </b-badge>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import customerStoreModule from '../customerStoreModule';
import vSelect from 'vue-select';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
    Treeselect
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'customer';
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, customerStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      fetchCustomers: [],
      tableColumns: [
        { key: 'contact', sortable: true, class: 'text-center' },
        { key: 'contactEmail', sortable: true, class: 'text-center' },
        { key: 'department', sortable: true, class: 'text-center' },
        { key: 'lab', sortable: true, class: 'text-center' },
        { key: 'jobIds', sortable: true, class: 'text-center' },
      ],
      perPage: parseInt(localStorage.getItem('formShow')) || 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],
      loading: true,
      searchQuery: '',
      selectedLab: [],
      selectedDepartment: [],
      labs: [],
      departments: [],
    };
  },

  watch: {
    perPage: 'debouncedRefetchFilter',
    currentPage: 'debouncedRefetchFilter',
    searchQuery: 'debouncedFilter',
    selectedLab: 'debouncedFilter',
    selectedDepartment: 'debouncedFilter',
  },

  methods: {
    debouncedRefetchFilter: function() {
      this.loading = true;
      clearTimeout(this.timer);
      localStorage.setItem('customerShow', this.perPage);
      this.timer = setTimeout(() => {
        this.getCustomers();
      }, 200);
    },

    debouncedFilter: function() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getCustomers();
      }, 1000);
    },

    onRowSelected(item) {
      
      router.push({ name: 'customer-edit', params: { userInfo: item[0] } });
    },

    getDepLabDatas() {
      this.loading = true;
      store
        .dispatch('customer/getDepLabData')
        .then((res) => {
          this.labs = res.data.labs.map((item) => ({ id: item, label: item }));
          this.departments = res.data.departments.map((item) => ({ id: item, label: item }));
          this.getCustomers();
        })
        .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });

          this.loading = false;
        });
    },


    getCustomers() {
      this.loading = true;
      store
        .dispatch('customer/getAllCustomers', {
          perPage: this.perPage,
          currentPage: this.currentPage,
          searchQuery: this.searchQuery,
          selectedLab: this.selectedLab,
          selectedDepartment: this.selectedDepartment,
        })
        .then((res) => {
          this.totalRows = res.data.total;
          this.fetchCustomers = res.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });
        });
    },

    exportExcel() {
      this.loading = true;
      store
        .dispatch("customer/exportExcelCustomer", {  
          searchQuery: this.searchQuery,
          selectedLab: this.selectedLab,
          selectedDepartment: this.selectedDepartment,})
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/octet-stream",
          });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "customers.xlsx");
          document.body.appendChild(link);
          link.click();
          this.loading = false;
;

        })
        .catch((error) => {
          console.log(error);
        });
    },

  },

  created() {
    this.getDepLabDatas();
  },

  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
